<template>
  <article class="page-cont w-1600 center pos-r" @click.stop="changeStatus(0)">
    <div class="bg pos-a"><img src="../assets/img/bg.jpg" /></div>
    <!-- <div class="ico-hand ico-hand-1 pos-a" @click.stop="changeStatus(1)"></div>
    <div class="ico-hand ico-hand-2 pos-a" @click.stop="changeStatus(2)"></div>
    <div class="ico-hand ico-hand-3 pos-a" @click.stop="changeStatus(3)"></div>
    <div class="ico-hand ico-hand-4 pos-a" @click.stop="changeStatus(4)"></div>
    <div class="ico-hand ico-hand-5 pos-a" @click.stop="changeStatus(5)"></div>
    <div class="ico-hand ico-hand-6 pos-a" @click.stop="changeStatus(6)"></div> -->

    <div
      class="dz move1 pos-a cursor"
      :class="{ dz1: lang == 'en' }"
      @click.stop="changeStatus(2)"
    ></div>
    <div
      class="xz move2 pos-a cursor"
      :class="{ xz1: lang == 'en' }"
      @click.stop="changeStatus(1)"
    ></div>
    <div
      class="xf move3 pos-a cursor"
      :class="{ xf1: lang == 'en' }"
      @click.stop="changeStatus(4)"
    ></div>
    <div
      class="yb move4 pos-a cursor"
      :class="{ yb1: lang == 'en' }"
      @click.stop="changeStatus(3)"
    ></div>
    <div
      class="qc move5 pos-a cursor"
      :class="{ qc1: lang == 'en' }"
      @click.stop="changeStatus(5)"
    ></div>
    <!-- <div
      class="lbj move6 pos-a cursor"
      :class="{ lbj1: lang == 'en' }"
      @click.stop="changeStatus(6)"
    ></div> -->

    <template v-for="(item, index) of cate">
      <transition name="slide-fade" :key="index">
        <div :class="'menu menu' + (index + 1)" v-show="status == index + 1">
          <div :class="'line' + (index + 1)"></div>
          <dl>
            <dt>{{ item.name }}</dt>
            <dd>
              <ul v-if="item.children.length > 0">
                <li v-for="(sub, subindex) of item.children" :key="subindex">
                  <a
                    class="block cursor"
                    :disabled="sub.children && sub.children.length > 0"
                    @click.stop="handleClick(sub)"
                    >{{ sub.name }}
                    <ul v-if="sub.children.length > 0">
                      <li v-for="(s, sindex) of sub.children" :key="sindex">
                        <router-link
                          :to="{
                            path: `/product?lang=${lang}&id=` + s.id,
                          }"
                          >{{ s.name }}</router-link
                        >
                      </li>
                    </ul>
                  </a>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
      </transition>
    </template>
  </article>
</template>

<script>
import { home } from "../api/index.js";
import langMinix from "../common/lang";
import { lang } from "../utils/utils";
import { Event } from "../utils/Event.js";
export default {
  name: "Index",
  components: {},
  data: () => {
    return {
      lang: lang,
      cate: [],
      status: 0,
    };
  },
  created() {
    Event.on("lang", this.home);
  },
  async mounted() {
    this.home();
  },
  minixs: [langMinix],
  methods: {
    changeStatus(status) {
      console.log(status);
      this.status = status;
    },
    //请求实例
    async home() {
      try {
        this.lang = location.href.includes("en") ? "en" : "zh";
        let res = await home(this.lang);
        console.log("res--->", res);
        if (res.code === 0) {
          this.cate = res.data.splice(0, 5);
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleClick(sub) {
      sub.active = true;
      console.log(sub);
      if (sub.children.length == 0) {
        this.$router.push(`/product?lang=${this.lang}&id=` + sub.id);
      } else {
        sub.active = true;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-cont {
  height: 1665px;
}

.bg {
  img {
    width: 100%;
  }
}
.menu {
  position: absolute;
  dt {
    height: 42px;
    background: #000000;
    border-radius: 5px 5px 0px 0px;
    font-size: 22px;
    font-family: ABB;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    padding-left: 29px;
  }
  dd ul li {
    height: 42px;
    line-height: 38px;
    background: rgba(242, 242, 242, 0.95);
    border-bottom: 1px solid #e0e0e0;
    font-size: 16px;
    font-family: ABB;
    font-weight: 400;
    white-space: nowrap;
    position: relative;

    a {
      color: #1a1a1a;
      display: block;
      padding: 3px 28px;
      ul {
        position: absolute;
        left: 300px;
        top: 0px;
        border-radius: 5px;
        display: none;
        padding: 3px 20px;
      }
    }
    a:hover {
      color: #ff000f;
      ul {
        display: block;
      }
    }
  }

  dd ul li:last-child {
    border-bottom: none;
  }
}
.cursor {
  cursor: pointer;
}
.move1 {
  animation: moveani 2s linear infinite alternate;
}

.move2 {
  animation: moveani 2s linear infinite alternate;
}

.move3 {
  animation: moveani 2s linear infinite alternate;
}

.move4 {
  animation: moveani 2s linear infinite alternate;
}
.move5 {
  animation: moveani 2s linear infinite alternate;
}

.move6 {
  animation: moveani 2s linear infinite alternate;
}

@keyframes moveani {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
.ico-hand {
  width: 80px;
  height: 76px;
  cursor: pointer;
  background: url("../assets/img/ico-hand.png") no-repeat;
  animation: suofang 2s linear infinite;
}

@keyframes suofang {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

.ico-hand-1 {
  top: 350px;
  left: 930px;
}

.ico-hand-2 {
  top: 655px;
  left: 530px;
}

.ico-hand-3 {
  top: 745px;
  left: 1110px;
}

.ico-hand-4 {
  top: 1050px;
  left: 550px;
}

.ico-hand-5 {
  top: 1268px;
  left: 1155px;
}

.ico-hand-6 {
  left: 780px;
  top: 1490px;
}

.menu2 {
  top: 488px;
  left: 248px;
  min-width: 301px;
}
.menu1 {
  top: 146px;
  left: 825px;
  min-width: 417px;
}
.menu3 {
  top: 547px;
  left: 919px;
  min-width: 300px;
}

.menu4 {
  top: 1016px;
  left: 246px;
  min-width: 301px;
}

.menu5 {
  top: 1237px;
  left: 780px;
  min-width: 309px;
}

.menu6 {
  top: 1374px;
  left: 594px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

.line1 {
  width: 183px;
  height: 183px;
  background: url("../assets/img/line-1.png") no-repeat;
  margin-left: 51px;
}
.line2 {
  width: 91px;
  height: 53px;
  background: url("../assets/img/line-2.png") no-repeat;
  margin-left: 51px;
}
.line2 {
  width: 91px;
  height: 53px;
  background: url("../assets/img/line-2.png") no-repeat;
  margin-left: 150px;
}
.line3 {
  width: 7px;
  height: 138px;
  background: url("../assets/img/line-3.png") no-repeat;
  margin-left: 148px;
}
.line4 {
  width: 110px;
  height: 68px;
  background: url("../assets/img/line-4.png") no-repeat;
  margin-left: 133px;
}
.line5 {
  width: 44px;
  height: 166px;
  background: url("../assets/img/line-5.png") no-repeat;
  margin-left: 120px;
}
.line6 {
  width: 185px;
  height: 65px;
  background: url("../assets/img/line-6.png") no-repeat;
}

.dz {
  width: 158px;
  height: 130px;
  background: url("../assets/img/dz.png") no-repeat;
  background-size: 158px 130px;
  left: 326px;
  top: 266px;
}
.dz1 {
  background: url("../assets/img/dz1.png") no-repeat;
  background-size: 158px 130px;
}

.xz {
  width: 158px;
  height: 130px;
  background: url("../assets/img/xz.png") no-repeat;
  background-size: 158px 130px;
  left: 670px;
  top: 55px;
}

.xz1 {
  background: url("../assets/img/xz1.png") no-repeat;
  background-size: 158px 130px;
}

.xf {
  width: 232px;
  height: 173px;
  background: url("../assets/img/xf.png") no-repeat;
  background-size: 232px 173px;
  top: 861px;
  left: 190px;
}
.xf1 {
  background: url("../assets/img/xf1.png") no-repeat;
  background-size: 232px 173px;
}

.yb {
  width: 158px;
  height: 130px;
  background: url("../assets/img/yb.png") no-repeat;
  background-size: 158px 130px;
  left: 64%;
  top: 368px;
}
.yb1 {
  background: url("../assets/img/yb1.png") no-repeat;
  background-size: 158px 130px;
}
.qc {
  width: 158px;
  height: 130px;
  background: url("../assets/img/qc.png") no-repeat;
  background-size: 158px 130px;
  left: 800px;
  top: 1020px;
}
.qc1 {
  background: url("../assets/img/qc1.png") no-repeat;
  background-size: 158px 130px;
}
.lbj {
  width: 158px;
  height: 130px;
  background: url("../assets/img/lbj.png") no-repeat;
  background-size: 158px 130px;
  left: 876px;
  top: 965px;
}
.lbj1 {
  background: url("../assets/img/lbj1.png") no-repeat;
  background-size: 158px 130px;
}

@media screen and (max-width: 1200px) {
  .page-cont {
    width: 1200px;
    height: calc(1665px * 0.75);
  }

  .menu2 {
    top: calc(488px * 0.75);
    left: calc(248px * 0.75);
  }
  .menu1 {
    top: calc(146px * 0.75);
    left: calc(825px * 0.75);
  }
  .menu3 {
    top: calc(547px * 0.75);
    left: calc(919px * 0.75);
  }

  .menu4 {
    top: calc(1016px * 0.75);
    left: calc(246px * 0.75);
  }

  .menu5 {
    top: calc(1237px * 0.75);
    left: calc(780px * 0.75);
  }
  .menu6 {
    top: calc(1374px * 0.75);
    left: calc(668px * 0.75);
  }

  .dz {
    top: calc(266px * 0.75);
    left: calc(326px * 0.75);
  }

  .xz {
    top: calc(55px * 0.75);
    left: calc(670px * 0.75);
  }

  .xf {
    top: calc(861px * 0.75);
    left: calc(190px * 0.75);
  }
  .yb {
    top: calc(368px * 0.75);
    left: 64%;
  }
  .qc {
    top: calc(1020px * 0.75);
    left: calc(800px * 0.75);
  }
  .lbj {
    top: calc(965px * 0.75);
    left: calc(876px * 0.75);
  }

  .bg {
    img {
      width: 1200px;
    }
  }
}
</style>
